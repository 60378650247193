<div class="modal-container" [style.display]="isOpened ? 'flex' : 'none'">
  <div class="modal center" [style]="{ width: width }">
    <div class="modal-icon icon peach" (click)="cancel()">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <h1 *ngIf="title">{{ title }}</h1>
    <div class="modal-body flex column ai-center">
      <ng-content></ng-content>
      <button class="action-button btn btn-primary j-center" (click)="confirm()">{{ 'GLOBAL.ANSWER.YES' | translate }}</button>
      <button class="action-button btn btn-primary j-center white" (click)="cancel()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    </div>
  </div>
</div>
