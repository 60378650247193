<div class="modal-container" [id]="'#selectDelegate'" [style.display]="isOpened ? 'flex' : 'none'">
  <div class="modal search-delegate" *ngIf="resourcesStore.isLoaded()">
    <div class="modal-icon icon peach" (click)="close()">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <h1>{{ resourcesStore.i18n().resources.popupFindConsultant.chooseConsultantTitle }}</h1>
    <div class="modal-body">
      <div class="search-form">
        <div class="flex column gap-8" *ngFor="let delegate of delegates">
          <div class="delegate-list order-contact-info flex row ai-center" (click)="selectDelegate(delegate)">
            <div class="img-container">
              <figure>
                <img loading="lazy" [src]="getProfilePictureOrDefault(delegate.profileImagePath)" />
              </figure>
            </div>
            <div class="teaser-container flex column flex-3 gap-10">
              <div class="header">{{ delegate.displayName | uppercase }}</div>
              <div class="location">
                <svg viewBox="0 0 32 32" class="icon peach">
                  <use xlink:href="#icon-location"></use>
                </svg>
                {{ delegate.city | uppercase }}
              </div>
            </div>
            <div class="delegate_list_arrow">
              <svg viewBox="0 0 240 208">
                <use xlink:href="#icon-arrow-right-long"></use>
              </svg>
            </div>
          </div>
        </div>
        <div class="flex column gap-8" *ngIf="delegates?.length == 0">
          <p class="error-box">{{ resourcesStore.i18n().resources.popupFindConsultant.labelNoConsultants }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
