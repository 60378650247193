import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss'],
})
export class EditDocumentComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
