import { Component, effect, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { Client } from 'src/app/core/models/client.model';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { SocketService } from 'src/app/core/services/sockets.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { getRootUrl, getVictoriaUrl } from 'src/app/core/utils/filter.utils';
import { DemoService } from 'src/app/features/agora-pages/demo/services/demo.service';
import { DemoModel } from '../../../features/agora-pages/demo/models/demo.model';
import { ScreenService } from 'src/app/core/services/screen.service';
import { RseItem } from '../../../core/types/size-dropdown.type';
import { UserStore } from '../../../stores/user.store';
import { ContextStore } from '../../../stores/context.store';
import { ResourcesStore } from '../../../stores/resources.store';
import { WishlistStore } from '../../../stores/wishlist.store';
import { CartStore } from '../../../stores/cart.store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  readonly userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  readonly resourcesStore = inject(ResourcesStore);
  readonly wishlistStore = inject(WishlistStore);
  readonly cartStore = inject(CartStore);

  langSettings = { country: 'Belgique (FR)', flag: '#icon-flag-be' };
  orderItemsCount = 0;

  @Input() isMenuOpen = false;
  @Input() isUserMenuOpen = false;

  @Output() openCloseMenu: EventEmitter<boolean> = new EventEmitter();
  @Output() openCloseUserMenu: EventEmitter<boolean> = new EventEmitter();

  @Input() isLogin = false;
  @Output() requestRegistration = new EventEmitter();

  dataLoaded: Subject<boolean> = new Subject();

  currentClient: Client = undefined;

  isMobileMenu = false;

  locale: string;
  demoCode: string;
  demoStatusId = 0;
  isDemoClient = false;
  demos: DemoModel[];

  private highLightTimer: number;
  public currentElement = 0;
  public rseBanner: RseItem[] = [];

  constructor(
    public dataService: GetDatasService,
    public storage: StorageService,
    private authService: OidcSecurityService,
    private route: Router,
    public jwt: JWTService,
    public translation: TranslationService,
    public demoService: DemoService,
    public socketService: SocketService,
    public screen: ScreenService
  ) {
    // effect(
    //   async () => {
    //     if (this.userStore.isAuthenticated() != null) {
    //       await this.wishlistStore.loadWishlist();
    //       await this.cartStore.loadCart();
    //     }
    //   },
    //   { allowSignalWrites: true }
    // );

    effect(() => {
      if (this.resourcesStore.rseBanners() && this.resourcesStore.rseBanners().length > 0) {
        if (!this.highLightTimer) {
          this.rseBanner = this.resourcesStore.rseBanners();

          this.highLightTimer = setInterval(_ => {
            if (this.currentElement == this.resourcesStore.rseBanners().length - 1) this.currentElement = 0;
            else this.currentElement++;
          }, 5000);
        }
      }
    });
  }

  ngOnInit() {
    this.updateLangageInfo(this.contextStore.region());
    this.route.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.hideAllMegaMenu(null, null);
        setTimeout(() => this.registerListeners(), 250);
      }
    });

    setTimeout(() => this.registerListeners(), 250);
  }

  public displayMenu(i) {
    const element = document.getElementsByClassName('mega_' + i)[0];
    if (element) {
      element?.classList.toggle('visible');
    }
  }

  public hideOthersSubMenu(i = -1) {
    const elements = Array.from(document.getElementsByClassName('mega-mobile'));
    for (const elem of elements) {
      elem.classList.remove('visible');
    }
  }

  public registerListeners() {
    this.requestRegistration.emit();
  }

  public showSideMenu() {
    if (this.isUserMenuOpen) this.showUserMenu();
    this.hideOthersSubMenu();

    if (document.getElementsByClassName('menu-visible')[0]) this.openCloseMenu.emit(false);
    else this.openCloseMenu.emit(true);

    document.getElementsByClassName('side-menu')[0]?.classList.toggle('menu-visible');
    document.getElementsByTagName('main')[0]?.classList.toggle('fixedHeight');
  }

  public closeMenu(i) {
    const element = document.getElementsByClassName('mega_' + i)[0];
    if (element) element?.classList.remove('visible');
  }

  public async showUserMenu() {
    if (!this.userStore.isAuthenticated()) {
      return this.route.navigate(['/' + this.contextStore.locale()]);
    }

    if (this.isMenuOpen) this.showSideMenu();

    if (document.getElementsByClassName('user-menu-visible')[0]) this.openCloseUserMenu.emit(false);
    else this.openCloseUserMenu.emit(true);

    document.getElementsByClassName('user-menu')[0]?.classList.toggle('user-menu-visible');
    document.getElementsByClassName('mega-user')[0]?.classList.toggle('visible');
    document.getElementsByTagName('main')[0]?.classList.toggle('fixedHeight');
  }

  public openCountries(mobile = false) {
    this.isMobileMenu = mobile;

    if (mobile) document.getElementsByClassName('lang-options-mobile')[0]?.classList.toggle('open');
    else document.getElementsByClassName('lang-options')[0]?.classList.toggle('open');
  }

  public currentLangage(code) {
    return this.contextStore.locale() == code;
  }

  public currentRegion(code) {
    return this.contextStore.region() == code;
  }

  public async changeLangage(code: string, region?: string) {
    const previousCode = this.contextStore.locale();
    const isSameCountry = (previousCode.endsWith('be') && code.endsWith('be')) || (previousCode.endsWith('de') && code.endsWith('de'));

    region = region || code;

    await this.contextStore.setLocale({ locale: code, region });
    await this.resourcesStore.loadResources(code);

    if (!isSameCountry) {
      this.jwt.deleteTokenCookieUsingLang(previousCode);
      this.authService.logoff();
    }

    window.location.href = getRootUrl(code, region);
  }

  public logout() {
    this.route.navigate(['/logout']);
  }

  public async localLogout() {
    this.authService.logoffLocal();
    this.jwt.deleteTokenCookie();

    await this.logoutRedirect();
  }

  public async checkEnvironnementAndLogin() {
    await this.route.navigate(['/' + this.contextStore.locale()]);
  }

  public async navigateToHome() {
    this.hideAllMegaMenu(null, null);
    await this.route.navigate(['/' + this.contextStore.locale()]);
  }

  public search() {
    window.location.href = this.getLogoUrl() + '/search';
  }

  public getLogoUrl() {
    return getVictoriaUrl(this.contextStore.locale(), true) + '/' + this.contextStore.locale();
  }

  //QuickFix Duplicate Code from AppComponent
  public hideAllMegaMenu(current, currentCarret) {
    const megaMenus = document.getElementsByClassName('mega-menu-content');
    const openCarrets = document.getElementsByClassName('carret-area');

    Array.from(megaMenus).forEach(item => {
      if (item != current) item?.classList.remove('block-menu');
    });

    Array.from(openCarrets).forEach(item => {
      if (item != currentCarret) item?.classList.remove('rotate-carret');
    });

    if (!current && !currentCarret) {
      const jumbo = document.getElementsByClassName('jumbo-shadow')[0];
      jumbo?.classList.remove('visible');
    }
  }

  public async goToDemo() {
    this.showUserMenu();
    this.hideAllMegaMenu(null, null);
    await this.route.navigate([`${this.contextStore.locale()}/myvictoria/demo/${this.userStore.userActiveDemoCode()}`]);
  }

  private async logoutRedirect() {
    await this.route.navigate(['/' + this.contextStore.locale()]);
  }

  private updateLangageInfo(code) {
    switch (code) {
      case 'fr-be':
        this.langSettings.country = 'Belgique (FR)';
        this.langSettings.flag = '#icon-flag-be';
        break;
      case 'nl-be':
        this.langSettings.country = 'België (NL)';
        this.langSettings.flag = '#icon-flag-be';
        break;
      case 'fr-fr':
        this.langSettings.country = 'France (FR)';
        this.langSettings.flag = '#icon-flag-fr';
        break;
      case 'de-de':
        this.langSettings.country = 'Deutschland (DE)';
        this.langSettings.flag = '#icon-flag-de';
        break;
      case 'de-at':
        this.langSettings.country = 'Österreich (DE)';
        this.langSettings.flag = '#icon-flag-at';
        break;
      case 'nl-nl':
        this.langSettings.country = 'Nederland (NL)';
        this.langSettings.flag = '#icon-flag-nl';
        break;
      case 'fr-lu':
        this.langSettings.country = 'Luxemburg (FR)';
        this.langSettings.flag = '#icon-flag-lu';
        break;
    }
  }
}
