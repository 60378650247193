<div class="demo-delegate-container">
  <div class="h2-container width100">
    <h2 class="bold">{{ 'DEMO.TUNNEL.ORDER.DELEGATE' | translate }}</h2>
  </div>
  <div class="flex row gap-20" *ngIf="delegate">
    <div class="card-img-container flex">
      <img loading="lazy" class="thumb-image" alt="delegate-img" [src]="getProfilePictureOrDefault(delegate.profileImage)" />
    </div>
    <div>
      <p class="bold">{{ (delegate.firstname + ' ' + delegate.lastname).trim() }}</p>
      <div class="row flex" *ngIf="delegate.city">
        <svg viewBox="0 0 32 32" class="icon peach height100">
          <use xlink:href="#icon-location"></use>
        </svg>
        &nbsp; {{ delegate.city }}
      </div>
    </div>
  </div>
</div>
