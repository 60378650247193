<div class="discount-box" *ngIf="voucherCount > 0">
  <div class="voucher-selection-container">
    <div class="header">{{ 'ORDER_TUNNEL.REDUCTIONS.CLUB' | translate }}</div>
    <div [innerHTML]="'ORDER_TUNNEL.REDUCTIONS.XVOUCHERSAVAILABLE' | translate: { X: availableCount }"></div>
    <div class="form-control flex row j-space-between ai-center input-box m-gap-20">
      <form [formGroup]="voucherForm">
        <select
          data-testId="voucher-selection"
          placeholder="Choisis ton Chèque Club"
          class="voucher-input"
          formControlName="voucher"
          [attr.disabled]="cart?.totalToPay - cart.deliveryFee > 0 && availableVouchers.length? null : ''">
          <option disabled selected value="default">{{ 'ORDER_TUNNEL.REDUCTIONS.CLUB.PLACEHOLDER' | translate }}</option>
          <option *ngFor="let voucher of availableVouchers" [value]="voucher.code">
            {{ voucher.amount | agoraCurrency }} - {{ 'GLOBAL.VOUCHER.TYPE_' + voucher.type.toString().toUpperCase() | translate }} - {{ voucher.code }}
          </option>
        </select>
      </form>
      <button
        data-testId="voucher-selection-action"
        class="btn btn-primary btn-animated {{ cart?.totalToPay - cart.deliveryFee > 0 && enableVoucherButton ? '' : 'disabled' }} add-voucher-done"
        [ngClass]="voucherForm.get('voucher').value != 'default' ? 'secondary' : ''"
        (click)="useVoucher()"
        [disabled]="stateStatus.isBusy">
        <span *ngIf="!stateStatus.added">{{ 'GLOBAL.ACTION.USE' | translate }}</span>
        <span *ngIf="stateStatus.added">{{ 'GLOBAL.ACTION.USED' | translate }}</span>
        <svg viewBox="0 0 32 32" class="icon add-voucher-check">
          <use xlink:href="#icon-checkmark"></use>
        </svg>
        <div *ngIf="stateStatus.isBusy" class="loader loader-mini"></div>
      </button>
    </div>
  </div>
</div>
