import { Component, inject, Input } from '@angular/core';
import { ResourcesStore } from '../../../../../../../stores/resources.store';
import { CartItemResponse, ProductSearchModel } from '@victoria-company/agora-client';
import { derivedAsync } from 'ngxtension/derived-async';
import { map, of } from 'rxjs';
import { CatalogService } from '../../../../../../../core/services/V2/catalog.service';
import { ContextStore } from '../../../../../../../stores/context.store';

@Component({
  selector: 'app-order-cart',
  templateUrl: './order-cart.component.html',
  styleUrls: ['./order-cart.component.scss'],
})
export class OrderCartComponent {
  @Input() items: CartItemResponse[];

  public resourcesStore = inject(ResourcesStore);
  private contextStore = inject(ContextStore);
  private catalogService = inject(CatalogService);

  public productsInCart = derivedAsync(() =>
    this.items?.length
      ? this.catalogService
          .getProductsByVariantIds(
            this.contextStore.contextId(),
            this.contextStore.locale(),
            this.items?.map(ci => ci.productVariantId)
          )
          .pipe(map(productResponse => productResponse.products))
      : of<ProductSearchModel[]>([]),
      { initialValue: [] }
  );
}
