<div class="pink-area fullwidth">
  <div class="form-container">
    <div class="form-block">
      <div class="form-control">
        <label>Titre</label>
        <input type="text" />
      </div>
    </div>
    <div class="form-block-flex">
      <div class="form-control">
        <label>Type</label>
        <select>
          <option>Action Cliente</option>
          <option>Guide Hôtesse</option>
        </select>
      </div>
      <div class="form-control">
        <label>Language</label>
        <select>
          <option>Français</option>
          <option>Néérlandais</option>
        </select>
      </div>
      <div class="form-control">
        <label>Date de début de l'action</label>
        <input type="text" />
      </div>
      <div class="form-control">
        <label>Date de fin de l'action</label>
        <input type="text" />
      </div>
      <div class="form-control">
        <label>Date de début de visibilité</label>
        <input type="text" />
      </div>
      <div class="form-control">
        <label>Date de fin de visibilité</label>
        <input type="text" />
      </div>
      <div class="form-control">
        <label>Description</label>
        <textarea></textarea>
      </div>
      <div class="form-control">
        <label>Image</label>
        <app-file-dropzone [dropzoneText]="'file upload area'" [style]="'min-height:15rem;'" />
      </div>
      <div class="form-control">
        <label>Pièce jointe</label>
        <app-file-dropzone [dropzoneText]="'attachment upload area'" />
      </div>
      <div class="form-control">
        <label>Lien</label>
        <input type="text" />
      </div>
      <div class="cta-container">
        <button><label>Enregistrer</label></button>
      </div>
    </div>
  </div>
</div>
