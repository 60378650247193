import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class DocumentsService {
  private readonly api = inject(ApiService);

  async getDocumentsForStaff() {
    const client = this.api.getClientInstance();
    return client.api.documents.get();
  }
  async getDocuments(demoCodeOrId: string, locale:string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).documents.byLocale(locale).get();
    // return new Promise<DocumentResponse[]>(res => res(this.mock));
  }
}
