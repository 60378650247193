import { DocumentResponse, GetDemoDocumentsResponse } from '@victoria-company/agora-client';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { DocumentsService } from '../core/services/V2/documents.service';
import { computed, inject } from '@angular/core';

export interface ActionState {
  documents: GetDemoDocumentsResponse;
  staffDocuments: GetDemoDocumentsResponse;
}

export const initialState = {
  documents: null,
  staffDocuments: null,
};

export const DocumentsStore = signalStore(
  { providedIn: 'root' },
  withDevtools('actions'),
  withState(initialState),
  withComputed(store => ({
    commercialActions: computed(() => store.documents()?.documents?.filter((d: DocumentResponse) => d.category == 'CommercialAction') ?? []),
    hostessActions: computed(() => store.documents()?.documents?.filter((d: DocumentResponse) => d.category == 'HostessGuide') ?? []),
    commercialActionsForStaff: computed(() => store.staffDocuments()?.documents?.filter((d: DocumentResponse) => d.category == 'CommercialAction') ?? []),
    hostessActionsForStaff: computed(() => store.staffDocuments()?.documents?.filter((d: DocumentResponse) => d.category == 'HostessGuide') ?? []),
  })),
  withMethods((store, documentsService = inject(DocumentsService)) => ({
    resetDocuments() {
      patchState(store, { documents: null });
    },
    async loadDocuments(demoCodeOrId: string, locale:string) {
      const documents = await documentsService.getDocuments(demoCodeOrId, locale);
      patchState(store, { documents });
    },
    async loadDocumentsForStaff() {
      const staffDocuments = await documentsService.getDocumentsForStaff();
      patchState(store, { staffDocuments });
    },
  }))
);
