import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserStore } from '../../../stores/user.store';
import { ContextStore } from '../../../stores/context.store';

@Component({
  selector: 'app-go-to-demo-banner',
  templateUrl: './go-to-demo-banner.component.html',
  styleUrls: ['./go-to-demo-banner.component.scss'],
})
export class GoToDemoBannerComponent {
  private router = inject(Router);
  private userStore = inject(UserStore);
  readonly contextStore = inject(ContextStore);
  goToDemo() {
    this.router.navigate([`/${this.contextStore.locale()}/myvictoria/demo/${this.userStore.userActiveDemoCode()}`]);
  }
}
