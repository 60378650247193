import { Component, inject, Input, OnInit } from '@angular/core';
import { DocumentCategoryObject, DocumentResponse } from '@victoria-company/agora-client';
import { ContextStore } from '../../../../../../stores/context.store';

@Component({
  selector: 'app-manage-document-card',
  templateUrl: './manage-document-card.component.html',
  styleUrls: ['./manage-document-card.component.scss'],
})
export class ManageDocumentCardComponent implements OnInit {
  contextStore = inject(ContextStore)
  @Input() document:DocumentResponse
  constructor() { }

  ngOnInit() {}

  getLocaleDisplayFromLocale(){
    switch(this.document.locale){
      case "fr-be": return "FR"
      case "nl-be": return "NL"
    }
  }

  protected readonly DocumentCategoryObject = DocumentCategoryObject;
}
