<div class="backToCartBanner">
  <img *ngIf="screen.isDesktop" src="/assets/images/logo.svg" />
  <img *ngIf="screen.isMobile" src="/assets/images/m-logo.svg" />
  <svg viewbox="0 0 32 32" (click)="goToCart()">
    <use xlink:href="#icon-close"></use>
  </svg>
</div>

<div class="order-container" *ngIf="resourcesStore.isLoaded() && cart && (payment || cart?.status == 'Finalized' || cart?.status == 'Confirmed')">
  <app-page-title [mainTitle]="resourcesStore.i18n().resources.checkout.mainTitle" [subTitle]="resourcesStore.i18n().resources.checkout.shortDescription"> </app-page-title>
  <app-form-stepper
    *ngIf="!cart.advantageScreenViewed"
    [currentStep]="CheckoutOrValidationProgressionStep.CONFIRMATION"
    [steps]="[
      { icon: null, step: null, title: resourcesStore.i18n().resources.checkout.mainSteps[0].label, canNavigate: false },
      { icon: null, step: CheckoutOrValidationProgressionStep.CONTACT, title: resourcesStore.i18n().resources.checkoutConsultant.secondTitle, canNavigate: false },
      { icon: null, step: CheckoutOrValidationProgressionStep.DELIVERY, title: resourcesStore.i18n().resources.checkout.mainSteps[1].label, canNavigate: false },
      { icon: null, step: CheckoutOrValidationProgressionStep.PAYMENT, title: resourcesStore.i18n().resources.checkout.mainSteps[2].label, canNavigate: false }
    ]"></app-form-stepper>
  <app-form-stepper
    *ngIf="cart.advantageScreenViewed"
    [currentStep]="CheckoutOrValidationProgressionStep.CONFIRMATION"
    [isDemo]="true"
    [steps]="[
      { step: CheckoutOrValidationProgressionStep.ADVANTAGES, icon: 'reduction', title: 'DEMO.TUNNEL.REDUCTIONS' | translate, canNavigate: false },
      { step: CheckoutOrValidationProgressionStep.DELIVERY, icon: 'truck', title: 'DEMO.TUNNEL.DELIVERY' | translate, canNavigate: false },
      { step: CheckoutOrValidationProgressionStep.PAYMENT, icon: 'bank-billet', title: 'DEMO.TUNNEL.PAYMENT' | translate, canNavigate: false }
    ]"></app-form-stepper>
  <div class="frame-container flex j-center ai-start">
    <app-order-payment-status
      *ngIf="cart.status == 'Finalized' || cart.status == 'Confirmed' || (payment && (payment?.status == 'Paid' || payment?.type == PaymentTypeObject.HandledByDelegate))"
      [paymentType]="cart.totalToPay == 0 ? undefined : payment?.type"
      [cart]="cart"
      [email]="userStore.profile()?.email"></app-order-payment-status>
    <app-order-payment-pending
      *ngIf="(cart.status == 'Active' || cart.status == 'PendingPayment') && payment?.status != 'Paid' && payment?.type != PaymentTypeObject.HandledByDelegate"></app-order-payment-pending>
  </div>
</div>
