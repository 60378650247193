<div class="wallet-card">
  <div class="header flex j-center" [routerLink]="['/' + locale + '/club/wallet']">
    <div class="card-title">
      <span class="status-text">{{ 'GLOBAL.WALLET' | translate }}</span>
    </div>
  </div>
  <div class="body {{ paddingBottom ? 'paddingBottom' : '' }}">
    <div class="wallet-icon" [routerLink]="['/' + locale + '/club/wallet']">
      <div class="card-img-container flex center-all">
        <img loading="lazy" src="assets/images/business/electronic-wallet.svg" />
      </div>
    </div>
    <div class="content">
      <div class="full-height">
        <div class="datas" [routerLink]="['/' + locale + '/club/wallet']">
          <div class="card-info flex column ac-center ai-center">
            <div class="info-main">
              <div class="sum flex column ac-center ai-center">
                {{ 'GLOBAL.XCASHBACK' | translate: { Amount: walletData.amount | agoraCurrency } }}
                <div class="voucher-sum">{{ 'GLOBAL.WITHXINVOUCHERS' | translate: { Amount: walletData.totalVouchers | agoraCurrency } }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions" *ngIf="showWalletButton">
          <div class="card-info flex column ac-center ai-center">
            <div class="card-action">
              <a class="btn btn-primary" [href]="this.translateConfig.getCollectionMenuLocalizedLink()">{{ 'GLOBAL.ENJOY' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer cursor" [routerLink]="['/' + locale + '/club/wallet']" *ngIf="walletData.hasAlertExpirationVoucher">
    <div class="footer-body flex column center-all">
      <div class="footer-title">
        {{ walletData.hasAlertExpirationVoucher ? (walletData.alertExpirationVoucherValue | agoraCurrency) : null }}
      </div>
      <div class="footer-expiration-date ac-center ai-center flex">
        <app-tooltip
          *ngIf="showTooltip"
          [text]="footerInformationTooltipText"
          [position]="footerInformationTooltipPosition"
          [width]="footerInformationTooltipWidth"
          [height]="footerInformationTooltipHeight">
          <div>
            {{ 'GLOBAL.EXPIREATDATEX' | translate: { Date: walletData.alertExpirationVoucherDate | date: 'dd/MM/yyyy' } }}
          </div>
        </app-tooltip>
        <div *ngIf="!showTooltip">
          {{ 'GLOBAL.EXPIREATDATEX' | translate: { Date: walletData.alertExpirationVoucherDate | date: 'dd/MM/yyyy' } }}
        </div>
      </div>
    </div>
  </div>
</div>
