import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { Wallet } from '../../../../core/models/wallet.model';

@Component({
  selector: 'app-wallet-card',
  templateUrl: './wallet-card.component.html',
  styleUrls: ['./wallet-card.component.scss'],
})
export class WalletCardComponent implements OnInit {
  @Input() walletData: Wallet;
  @Input() showTooltip = false;
  @Input() footerInformationTooltipText: string;
  @Input() footerInformationTooltipPosition: string;
  @Input() footerInformationTooltipWidth: string;
  @Input() footerInformationTooltipHeight: string;
  @Input() showWalletButton: boolean;
  @Input() paddingBottom = true;

  locale: string;

  constructor(
    public translateConfig: TranslationService,
    private cookies: CookieService
  ) {}

  ngOnInit() {
    this.locale = this.cookies.get('v_locale')?.replace('_', '-');
  }
}
