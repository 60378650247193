<div *ngIf="resourcesStore.isLoaded()" class="modal-container" [style.display]="cartStore.addToCartSelectProductVariantModal().isOpened ? 'flex' : 'none'">
  <div class="modal" *ngIf="cartStore.addToCartSelectProductVariantModal().isOpened">
    <div class="modal-icon icon peach" (click)="cancel()">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-close"></use>
      </svg>
    </div>
    <div *ngIf="viewNextStep" class="flex cursor" (click)="stepBack()">
      <svg viewBox="0 0 32 32" class="icon">
        <use xlink:href="#icon-arrow-left-long"></use>
      </svg>
      {{ resourcesStore.i18n().resources.popupFindConsultant.backLabel }}
    </div>
    <h1 *ngIf="!viewNextStep">{{ resourcesStore.i18n().resources.popupPickSize.mainTitle }}</h1>
    <h1 *ngIf="viewNextStep">{{ resourcesStore.i18n().resources.sizeGuide.mainTitle }}</h1>
    <div class="modal-body size" *ngIf="!viewNextStep">
      <p>{{ resourcesStore.i18n().resources.popupPickSize.description | removeHtmlTag }}</p>
      <div class="promo-form">
        <h3 class="lightbold">{{ cartStore.addToCartSelectProductVariantModal().product?.name }}</h3>
        <div class="flex column gap-15">
          <div class="flex gap-8 ai-center">
            <select class="control-select" (change)="changeSelectedVariantId($event.target)">
              <option
                *ngFor="let variant of cartStore.addToCartSelectProductVariantModal()?.product?.variants"
                [value]="variant.id"
                [selected]="variant.id == cartStore.addToCartSelectProductVariantModal().selectedVariantId"
                [disabled]="!getVariantAvailability(variant)"
                [class.barre]="!getVariantAvailability(variant)">
                {{ variant?.size }}
              </option>
            </select>
            <div class="flex cursor ai-center" (click)="goNextStep()">
              <svg viewBox="0 0 32 32" class="icon">
                <use xlink:href="#icon-size"></use>
              </svg>
              <span class="mini-text">{{ resourcesStore.i18n().resources.shoppingBag.sizesLabels }}</span>
            </div>
          </div>
          <button
            [disabled]="!selectedVariantAvailable()"
            [class.disabled]="!selectedVariantAvailable()"
            class="btn btn-primary j-center"
            (click)="addToCart(cartStore.addToCartSelectProductVariantModal().selectedVariantId)">
            {{ resourcesStore.i18n().resources.popupPickSize.addToBagLabel }}
          </button>
        </div>
      </div>
    </div>
    <div class="modal-body modal-size-guide" *ngIf="viewNextStep">
      <!-- Tab Area -->
      <div class="flex j-start selection scrollable">
        <div
          *ngFor="let item of resourcesStore.i18n().resources.sizeGuide.tabs; let i = index"
          class="tab tab-auto flex center-all no-wrap selection-title"
          [class.selected]="selectedTab == i.toString()"
          (click)="switchTab(i.toString())">
          {{ item.tabLabel }}
        </div>
      </div>
      <!-- Tab Content Area-->
      <div class="search-form">
        <ng-template [ngFor] let-item [ngForOf]="resourcesStore.i18n().resources.sizeGuide.tabs" let-i="index">
          <div class="flex column gap-8 tab-details" *ngIf="selectedTab == i.toString()">
            <p>{{ item.description }}</p>
            <div class="flex ai-start gap-20 m-column">
              <figure *ngIf="item.imagePoster.length > 0" class="flex-1">
                <img loading="lazy" [src]="item.imagePoster[0].url" [alt]="item.imagePoster[0].filename" />
              </figure>
              <div class="flex-1 flex column table-container">
                <div class="flex j-space-around">
                  <div class="flex column gap-20 flex-1">
                    <div class="flex column gap-20">
                      <span class="lightbold table-head">{{ item.tableHeadSizeLabel }}</span>
                    </div>
                  </div>
                  <div class="flex column gap-20 flex-1">
                    <div class="flex column gap-20">
                      <span class="lightbold table-head">{{ item.tableHeadCircumferenceLabel }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex gap-10 j-space-around table-size">
                  <div class="flex column gap-20 flex-1">
                    <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">
                      <span>{{ tbl.col1 }}</span>
                    </div>
                  </div>
                  <div class="flex column gap-20 flex-1">
                    <div *ngFor="let tbl of item.table; let j = index" class="flex column gap-20">
                      <span>{{ tbl.col2 }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
