
<div class="cta-container btn-create-document">
  <button class="secondary" (click)="navigateTo('create')">
    <label>{{"STAFF.MANAGE." + category + ".CREATE" | translate}}</label>
    <svg class="icon peach" viewBox="0 0 20 20">
      <use xlink:href="#icon-cross" />
    </svg>
  </button>
</div>
<div class="documents-list-container">
  @for(document of documents(); track document){
    <app-manage-document-card [document]="document" />
  }
</div>
